import { Col, Row, Spin } from 'antd';
import { Fragment } from 'react';
// import { Result } from 'antd';

const LoadingPage = () => {
  return (
    <Fragment>
      {/* <Result status="warning" title="Please wait while we fetch the data..." /> */}
      <Row justify="center" align="middle" style={{ height: '100vh' }}>
        <Col>
          <Spin size="large" spinning></Spin>
        </Col>
      </Row>
    </Fragment>
  );
};

export default LoadingPage;
