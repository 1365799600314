import { ComponentType } from 'react';
import { Grid } from 'antd';

const { useBreakpoint } = Grid;

export default function withBreakpoint<S>(Component: ComponentType<S>) {
  return (props: S) => {
    const screens = useBreakpoint();
    return <Component {...props} screens={screens} />;
  };
}
