export function setInLocal(key: string, value: string | number | object): void {
  localStorage && localStorage.setItem(key, JSON.stringify(value));
}

export function getFromLocal(key: string) {
  if (localStorage) {
    const storage: string | null = localStorage?.getItem(key);

    if (storage) {
      try {
        const jsonData = JSON.parse(storage);
        return jsonData;
      } catch (error) {
        return '';
      }
    }
  }
}

export function removeFromLocal(key: string): void {
  localStorage && localStorage.removeItem(key);
}

export function setInSession(
  key: string,
  value: string | number | object
): void {
  sessionStorage && sessionStorage.setItem(key, JSON.stringify(value));
}

export function getFromSession(key: string) {
  if (localStorage) {
    const storage: string | null = localStorage.getItem(key);

    if (storage) {
      try {
        const jsonData = JSON.parse(storage);
        return jsonData;
      } catch (error) {
        return '';
      }
    }
  }
}

export function removeFromSession(key: string): void {
  sessionStorage && sessionStorage.removeItem(key);
}
